import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type IndexPageProps = {
    location: any;
};

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;

const CCCDocumentPage = ({ location }: IndexPageProps) => {
    const TARGET_ID = 'hubspot-target';

    useEffect(() => {
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: 'd93cc969-773e-4248-a9df-62a70e1f28dc',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <Layout location={location}>
            <Helmet>
                <title>Free Managed IT Services Benefits Guide</title>
            </Helmet>

            {/*begin header*/}
            <div className={'bgsection center mb-5'}>
                <div className="container960">
                    <div className="row">
                        <div className={'col-md-8 mt-5'}>
                            <h1 className="mb-5">The FinOps Guide to Maximize Cloud ROI and Minimize Costs</h1>
                            <h4 className="primary">
                                Download this free guide to discover the game-changing strategies to master Cloud Cost
                                Control and elevate your FinOps practices.
                            </h4>
                        </div>
                        <div className={'col-md-4'}>
                            <img className="img-fluid" src="../images/ccc/ccc-sales-letter.png"></img>
                        </div>
                    </div>
                </div>
            </div>
            {/*end header*/}

            {/*sign up form*/}
            <div className="container mb-5">
                <div className="row">
                    <div className={'col-lg-6'}>
                        <h3>Cloud Cost Control Guide</h3>
                        <h4>The Guide to Optimizing FinOps for Business Growth</h4>
                        <p className="mainText">
                            Are your cloud costs spiraling out of control? Struggling with outdated cost management
                            methods?
                        </p>
                        <p className="mainText">
                            Trusted by industry leaders, SquareHook brings you actionable insights to revolutionize your
                            FinOps approach.
                        </p>
                        <p className="mainText emphasis">
                            Sign up here to get the document, future news, and industry insights.
                        </p>
                    </div>
                    <div className={'col-lg-6 m-auto'} style={{ maxWidth: '620px' }}>
                        <h3 className={'center'}>GET THE FREE DOCUMENT TODAY</h3>
                        <div id={TARGET_ID}></div>
                    </div>
                </div>
            </div>
            {/*end sign up form*/}

            <div className="container960 mb-5">
                <div className="row">
                    <div className="col-sm-3 center mb-3">
                        <img
                            className="img-fluid"
                            style={{ maxHeight: '250px' }}
                            src="/images/ccc/finops-cert.png"
                            alt="SquareHook FinOps Certified Partner"></img>
                    </div>
                    <div className="col-sm-9">
                        <h3 className=" mb-2">Discover How FinOps Can Help Your Business</h3>
                        <p>
                            Learn how FinOps seamlessly integrates finance and DevOps, empowering businesses to optimize
                            cloud spending with data-driven and cos-effective decisions.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                            &quot;If it seems that FinOps is about saving money, then think again.{' '}
                            <span style={{ fontWeight: 'bold' }}>FinOps is about making money.</span>&quot;
                        </p>
                    </div>
                </div>
            </div>
            <div className="container960">
                <h3 className="mb-3 pt-3 center">Learn about the four steps needed to make FinOps work for you</h3>
                <div className="row center">
                    <div className="col-sm-6 mb-4">
                        <h4>Routine Cost Analysis</h4>
                        <img
                            className="img-fluid"
                            src="/images/ccc/detailed-breakdown.png"
                            style={{ maxHeight: '230px' }}
                            alt="magnifying glass with cost breakdown"
                        />
                    </div>
                    <div className="col-sm-6 mb-4">
                        <h4>Discounts and Pricing Models</h4>
                        <img
                            className="img-fluid"
                            src="/images/ccc/savings.png"
                            style={{ maxHeight: '230px' }}
                            alt="piggy bank with dollars"
                        />
                    </div>
                </div>
                <div className="row center">
                    <div className="col-sm-6 mb-4">
                        <h4>Data-Driven Optimization</h4>
                        <img
                            className="img-fluid"
                            src="/images/ccc/monitoring.png"
                            style={{ maxHeight: '230px' }}
                            alt="chart graph"
                        />
                    </div>
                    <div className="col-sm-6 mb-4">
                        <h4>Continuous Monitoring</h4>
                        <img
                            className="img-fluid"
                            style={{ maxHeight: '230px' }}
                            src="/images/ccc/management.png"
                            alt="person monitoring graphs"
                        />
                    </div>
                </div>
            </div>
            <h3 className="center mb-5">Just fill in your info above to receive the free document</h3>

            {/*CTA section*/}
            <div className="bgsection">
                <div className="container960 my-5">
                    <h2 className="center">READY TO START SAVING ON YOUR CLOUD NOW?</h2>
                    <h3 className="primary center">
                        Get a <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>free savings analysis</span> of
                        your current system.
                    </h3>

                    <h3 className="center mt-5">What you&apos;ll get with a free chat:</h3>
                    {/* bigger-mid screens */}
                    <div className="row d-none d-md-flex">
                        <div className="col-md-4">
                            <h4>Free Analysis</h4>
                            <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                            <p>Thorough automated analysis examining 5 different areas.</p>
                        </div>
                        <div className="col-md-4">
                            <h4>Free Savings Report</h4>
                            <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                            <p>Comprehensive report shows specific areas where you can save.</p>
                        </div>
                        <div className="col-md-4">
                            <h4>Recommended Plan</h4>
                            <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                            <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                        </div>
                    </div>

                    {/* smaller screens */}
                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                        </div>
                        <div className="col-9">
                            <h4>Free Analysis</h4>
                            <p>Thorough automated analysis examining 5 different areas.</p>
                        </div>
                    </div>

                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                        </div>
                        <div className="col-9">
                            <h4>Free Savings Report</h4>
                            <p>Comprehensive report shows specific areas where you can save.</p>
                        </div>
                    </div>

                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                        </div>
                        <div className="col-9">
                            <h4>Recommended Plan</h4>
                            <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                        </div>
                    </div>

                    <p className="center mainText pt-5">
                        <a className="button-primary" href="/ccc-contact">
                            Book A Chat
                        </a>
                    </p>
                </div>
            </div>
            {/* end cta */}
        </Layout>
    );
};
export default CCCDocumentPage;
